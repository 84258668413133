import styles from './About.styles.module.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Card, CardMedia, Typography, IconButton, Grid } from '@mui/material';

const About = () => {
    return (
        <Grid container className={styles.container}>
            {/* Page title */}
            <Grid item xs={12}>
                <Typography
                    variant="h1"
                    sx={{
                        fontSize: { xs: '2rem', sm: '3rem' },
                        color: 'var(--text-color)',
                        textAlign: { xs: 'center' },
                    }}
                >
                    About
                </Typography>
            </Grid>
            {/* Page Info  */}
            <Grid item>
                {/* Image */}
                <Grid item>
                    <Card
                        sx={{
                            boxShadow: 0,
                            marginBottom: '2rem',
                        }}
                    >
                        <CardMedia
                            component="img"
                            alt={'Peter Eisenschmidt'}
                            sx={{
                                objectFit: 'contain',
                                height: '300px',
                            }}
                            src={require('../../assets/picpeter.jpg')}
                            loading="lazy"
                        />
                    </Card>
                </Grid>
                {/* Info */}
                <Grid
                    item
                    sx={{
                        marginBottom: '2rem',
                    }}
                >
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: {
                                xs: '1rem',
                                sm: '1.5rem',
                                marginBottom: '1rem',
                            },
                        }}
                    >
                        Peter Eisenschmidt is a photographer
                        <br />
                        based in Barcelona, Spain.
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: { xs: '1rem', sm: '1.5rem' },
                            marginBottom: '1rem',
                        }}
                    >
                        Work inquiries are welcome on
                        <br />
                        Instagram or via e-Mail:
                        <br />
                        info@picpeter.com
                    </Typography>
                </Grid>
                {/* Instagram */}
                <Grid item sx={{ marginBottom: '6rem'}}>
                    {/* PE insta */}
                    <Typography
                        variant="body2"
                        sx={{ fontSize: { xs: '1rem', sm: '1.5rem' } }}
                    >
                        @peter_eisenschmidt_bcn
                        <IconButton
                            href="https://www.instagram.com/peter_eisenschmidt_bcn/"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.socialMediaLink}
                        >
                            <InstagramIcon
                                sx={{
                                    fontSize: 40,
                                    color: 'var(--text-color)',
                                }}
                            />
                        </IconButton>
                    </Typography>
                    {/* PicPeter Insta */}
                    <Typography
                        variant="body2"
                        sx={{
                            fontSize: { xs: '1rem', sm: '1.5rem' },
                        }}
                    >
                        @picpeter.com_photography
                        <IconButton
                            href="https://www.instagram.com/picpeter.com_photography/"
                            target="_blank"
                            rel="noreferrer"
                            className={styles.socialMediaLink}
                        >
                            <InstagramIcon
                                sx={{
                                    fontSize: 40,
                                    color: 'var(--text-color)',
                                }}
                            />
                        </IconButton>
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default About;
